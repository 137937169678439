import React from 'react'
import PropTypes from 'prop-types'

import curly from '../images/aeropuerto_curl_path_brush2_white.png'

const Header = (props) => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      {/* <span className="icon fa-diamond"></span> */}
      <img alt="" src={curly} style={{ width: 100 + '%' }} />
    </div>
    <div className="content">
      <div className="inner">
        <h1>Motel El Aeropuerto</h1>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('hotel')
            }}
          >
            El Motel
          </a>
        </li>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('rooms')
            }}
          >
            Habitaciones
          </a>
        </li>
        {/* <li><a href="javascript:;" onClick={() => {props.onOpenArticle('about')}}>Quienes somos</a></li> */}
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contáctanos
          </a>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
