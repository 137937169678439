import React from 'react'

import hotel1 from '../images/approach_graded_squash.jpg'
import hotel2 from '../images/IMG_3245_size.jpg'

class PageElMotel extends React.Component {
  render() {
    return [
      <h2 className="major" key="one">
        El Motel
      </h2>,
      <span className="image main" key="two">
        <img src={hotel1} alt="" />
      </span>,
      <p key="three">Bienvenidos al Motel El Aeropuerto</p>,
      <p key="four">
        Nosotros hemos estado brindando nuestros servicios con orgullo a la
        hermosa ciudad de Tuluá, corazón de el Valle del Cauca, por más de 40
        años.
      </p>,
      <p key="five">
        Desde nuestro humilde comienzo, cuando tan solo teníamos siete cuartos,
        nos hemos empeñado a sobresalir y demostrarle a nuestros visitantes cuán
        importante su comodidad es para nosotros. Actualmente, con cincuenta y
        dos habitaciones, nuestra principal misión continúa siendo que nuestros
        cliente se hallen completamente satisfechos.
      </p>,
      <p key="six">
        Si se halla en nuestra página debe estar buscando el mejor lugar para
        llevar a esa persona especial.
      </p>,
      <p key="seven">No necesita buscar más.</p>,
      <span className="image main" key="eleven">
        <img src={hotel2} alt="" />
      </span>,
      <p key="eight">
        En el Motel El Aeropuerto usted puede disfrutar de un espacio limpio e
        íntimo por un precio ideal para cualquier presupuesto. Cada habitación
        viene equipada con televisor, aire acondicionado, y acceso a WiFi.
        Bebidas y mecato también están disponibles para comprar.
      </p>,
      <p key="nine">
        Nada es más importante para nosotros que brindarle el mejor servicio
        posible. Permítanos decorar su cuarto para una ocasión especial.
        Permítanos hacer que su estadìa sea tan cómoda y placentera como usted
        se merece.
      </p>,
      <p key="ten">
        Le agradecemos por considerar nuestro establecimiento. Por favor, pase
        cuando desee, día o noche. Estamos abiertos veinticuatro horas al día
        todo el año.
      </p>,
    ]
  }
}

export default PageElMotel
