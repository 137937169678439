import React from 'react'

class PageContact extends React.Component {
  render() {
    return [
      <h2 className="major" key="one">
        Contáctanos
      </h2>,
      <form method="POST" key="two" name="contact" netlify>
        <input type="hidden" name="form-name" value="contact" />
        <div className="field half first">
          <label htmlFor="name">Nombre</label>
          <input type="text" name="name" id="name" />
        </div>
        <div className="field half">
          <label htmlFor="email">Email</label>
          <input type="text" name="email" id="email" required />
        </div>
        <div className="field">
          <label htmlFor="message">Preguntas y comentarios</label>
          <textarea name="message" id="message" rows="4" />
        </div>
        <ul className="actions">
          <li>
            <input type="SUBMIT" value="Enviar Mensaje" className="special" />
          </li>
          <li>
            <input type="reset" value="Borrar" />
          </li>
        </ul>
      </form>,
      <p key="four">
        Dirección: ​Calle 26G via al Aeropuerto, Tuluá, Valle del Cauca,
        Colombia
      </p>,
      <ul className="icons" key="three">
        <li>
          <a
            href="https://www.facebook.com/motelelaeropuerto/"
            className="icon fa-facebook"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/motelelaeropuerto/"
            className="icon fa-instagram"
          >
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>,
    ]
  }
}

export default PageContact
