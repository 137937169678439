import React from 'react'
import RoomsSlider from './RoomsSlider'
import roomsheader from '../images/IMG_0398_graded.jpg'

// This page has been changed and I don't want to mess with it so I'm creating a fake comment -- Igor

class PageHabitaciones extends React.Component {
  render() {
    return [
      <h2 className="major" key="one">
        habitaciones
      </h2>,
      <span className="image main" key="two">
        <img src={roomsheader} alt="" />
      </span>,
      <ul key="three">
        <li>
          Habitación Sencilla - $35,000 / 3 horas + $6,000 por hora adicional.*
        </li>
        <li>
          Habitación con Jacuzzi y/o Baño Turco - $ 43,000 / 3 horas + $6,000 por
          una hora adicional.*
        </li>
        <li>
          Habitación Doble - $65,000 / 3 horas + $10,000 por hora adicional.*
        </li>
      </ul>,
      <div key="four">
        <RoomsSlider />
      </div>,
      <p key="five">
        *Todos los precios son sujeto a cambio sin previo aviso.
      </p>,
    ]
  }
}

export default PageHabitaciones
