import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import room1 from '../images/IMG_0384_grad.jpg'
import room2 from '../images/Jacuzzi Orange.jpg'
import room3 from '../images/IMG_0396.jpg'
import room5 from '../images/Sencilla Azul.jpg'

class RoomsSlider extends React.Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    }
    return (
      <Slider {...settings}>
        <img src={room1} alt="" />
        <img src={room2} alt="" />
        <img src={room3} alt="" />
        <img src={room5} alt="" />
      </Slider>
    )
  }
}

export default RoomsSlider
